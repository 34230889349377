import React, {useEffect} from 'react';
import styles from './index.module.scss'
import Fixture from "./components/Fixture/Fixture";
import background from "../../img/ResultBackground.png"
import logo from "../../img/MSSLogoMain.png"
import {Icon} from "semantic-ui-react";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {useParams} from "react-router-dom";
import {getDailyResults} from "../../store/dailyResults/dailyResultSlice";
import moment from "moment/moment";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {downloadDailyResult} from "./helpers/downloadDailyResult";

const DailyResults = () => {
    const dispatch = useAppDispatch()
    const {tournaments, competitionTime, isFinalGroup, loader} = useAppSelector(state => state.dailyResult)
    const {tourId} = useParams()

    useEffect(() => {
        dispatch(getDailyResults(tourId))
    }, [dispatch, tourId]);


    const formatDateTitle = () => {
        const {from, to} = competitionTime
        if (moment(from).year() < 2005) {
            return ""
        }
        if (from && !to) {
            return `${moment(from).format("dddd Do MMMM")}`
        }
        if (from && to && from === to) {
            return `${moment(from).format("dddd Do MMMM")}`
        }
        if (from && to) {
            return `${moment(from).format("dddd Do MMMM")} - ${moment(to).format("dddd Do MMMM")}`
        }
    }

    return (
        <div className={styles.page}>
            {loader && <OverlayLoader/>}
            <div className={styles.background} style={{backgroundImage: `url(${background})`}}></div>
            <div className={styles.container}>
                <div className={styles.downloadBlock}>
                    <div className={styles.iconBlock}>
                        <Icon
                            onClick={() => downloadDailyResult(tourId)}
                            size={"large"}
                            className={styles.icon}
                            name={"download"}
                        />
                    </div>
                </div>
                <div className={styles.titleContent}>
                    <h1 className={styles.titleDate}>{formatDateTitle()}</h1>
                    <div className={styles.pageTitle}>
                        {!loader && <div className={styles.separatorByGroup}></div>}
                        <h1 className={styles.titleGroup}>{tournaments[0]?.name}</h1>
                    </div>
                    <div className={styles.decorContent}>
                        <div className={styles.decor1}></div>
                        <div className={styles.decor2}></div>
                        <div className={styles.decor3}></div>
                        <div className={styles.decor4}></div>
                    </div>
                </div>
                {isFinalGroup !== null && !isFinalGroup && !loader &&
                    <div className={styles.fixturesContent}>
                        <div className={styles.fixtures}>
                            {tournaments[0]?.fixtures?.slice(0, 8)?.map(fixture => {
                                return (
                                    <Fixture key={fixture.gameId} fixture={fixture}/>
                                )
                            })}
                        </div>
                        <div className={styles.fixtures}>
                            {tournaments[0]?.fixtures?.slice(8)?.map(fixture => {
                                return (
                                    <Fixture key={fixture.gameId} fixture={fixture}/>
                                )
                            })}
                        </div>
                    </div>
                }
                {isFinalGroup !== null && isFinalGroup && !loader &&
                    <div className={styles.fixturesContentFinal}>
                        <div>
                            <div className={`${styles.fixtures}`}>
                                {tournaments[0]?.fixtures?.map((fixture, index) => {
                                    if (index + 1 <= 3) {
                                        return (
                                            <Fixture key={fixture.gameId} fixture={fixture}/>
                                        )
                                    } else return null

                                })}
                                <div className={`${styles.fixtures}`}>
                                    {tournaments[1]?.fixtures?.map((fixture, index) => {
                                        if (index + 1 <= 3) {
                                            return (
                                                <Fixture key={fixture.gameId} fixture={fixture}/>
                                            )
                                        } else return null
                                    })}
                                </div>
                            </div>
                        </div>
                        <h1 className={styles.goldTitle}>FINAL</h1>
                        <h1 className={styles.semiFinalTitle}>SEMI</h1>
                        <h1 className={styles.groupsTitle}>GROUP</h1>
                        <div className={styles.finalBlock}>
                            <div>
                                <div className={`${styles.fixtures} ${styles.semiFixtures}`}>
                                    {tournaments[2]?.fixtures?.map((fixture, index) => {
                                        if (index + 1 <= 2) {
                                            return (
                                                <Fixture key={fixture.gameId} fixture={fixture}/>
                                            )
                                        } else return null
                                    })}
                                </div>
                            </div>
                            <div className={styles.commonFixturesInner}>
                                <div className={`${styles.fixtures} ${styles.finalFixture}`}>
                                    {tournaments[3]?.fixtures?.map((fixture, index) => {
                                        if (index + 1 <= 1) {
                                            return (
                                                <Fixture key={fixture.gameId} fixture={fixture}/>
                                            )
                                        } else return null
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div
                className={styles.logo}
                style={{backgroundImage: `url(${logo})`}}>
            </div>
        </div>
    );
};

export default DailyResults;
