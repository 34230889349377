import React, {useEffect, useState} from 'react';
import Dartboard from "../../components/Dartbord/dartboard_lib";

interface IDartsBoardProps {
    dartboardRef: React.MutableRefObject<HTMLDivElement | null>;
    defaultSizeOfDartboard: number
    mobileView: boolean
}

const PlayBoard: React.FC<IDartsBoardProps> = ({dartboardRef, defaultSizeOfDartboard, mobileView}) => {
    const [size, setSize] = useState<{ width: Number; height: number }>({
        width: defaultSizeOfDartboard,
        height: defaultSizeOfDartboard,
    });

    useEffect(() => {
        // https://www.npmjs.com/package/dartboard
        if (dartboardRef.current?.id) {
            dartboardRef.current.innerHTML = "";
            window.addEventListener("resize", handleWindowResize);
            const dartboard = new Dartboard(`#${dartboardRef.current.id}`);
            dartboard.render();
        }

        return () => {
            if (dartboardRef.current?.id) {
                window.removeEventListener("resize", handleWindowResize);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size]);

    const handleWindowResize = () => {
        const width = window.innerHeight * 0.9;
        const height = width;

        setSize({width, height});

        if (dartboardRef.current) {
            dartboardRef.current.innerHTML = "";
            new Dartboard(`#${dartboardRef.current.id}`).render();
        }
    };

    return (
        <div
            ref={dartboardRef}
            id="dartboard"
            style={{
                width: `${mobileView ? 500 : size.width}px`,
                height: `${mobileView ? 500 : size.height}px`,
            }}
        />
    );
};

export default PlayBoard;
