import React, {useState} from 'react';
import styles from "./index.module.scss"
import PlayBoard from '../DartBoard/PlayBoard';
import {Button} from "semantic-ui-react";
import ActionButtons from "../../../MatchContainer/components/ActionButtons/ActionButtons";
import {GameStatus, GameStatuses} from "../../../../enums/gameStatus";
import {useAppSelector} from "../../../../hooks/hooks";
import PlayerBoard from "./PlayerBoard/PlayerBoard";

interface ITabletsBoardProps {
    dartboardRef: React.MutableRefObject<HTMLDivElement | null>;
    defaultSizeOfDartboard: number
    onClickActionButton: (eventStatus: GameStatus, newStatus: GameStatuses, message: string) => void;
    handleBounce: () => void
    handleUndo: (isAllowed: boolean) => void
    setIsOpenEndGameModal: (flag: boolean) => void;
}

const TabletsBoard: React.FC<ITabletsBoardProps> = (
    {
        dartboardRef,
        defaultSizeOfDartboard,
        onClickActionButton,
        handleBounce,
        handleUndo,
        setIsOpenEndGameModal
    }) => {
    const {currentGameInitData: {lastTurns},isUndoThrowAllowed} = useAppSelector((state) => state.currentDartsGame);
    const [isShowActionStatusModal, setIsShowActionStatusModal] = useState(false);

    return (
        <div className={styles.board}>
            <div className={styles.contentWrapper}>
                <div className={styles.infoAndControls}>
                    {lastTurns?.map(playerInfo => {
                        return (
                            <PlayerBoard playerInfo={playerInfo}/>
                        )
                    })}

                    <div className={styles.playButtons}>
                        <Button
                            onClick={handleBounce}
                            color="blue">
                            Bounce Out
                        </Button>
                        <Button
                            onClick={() => setIsShowActionStatusModal(true)}
                            color={"yellow"}
                        >
                            Change match status
                        </Button>
                        <Button
                            onClick={()=>handleUndo(isUndoThrowAllowed)}
                            color="red"
                        >
                            Undo
                        </Button>
                    </div>
                    {isShowActionStatusModal &&
                        <div
                            onClick={() => setIsShowActionStatusModal(false)}
                            className={styles.statusModal}
                        >
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className={styles.actionBlockWrapper}>
                                <ActionButtons
                                    setIsOpenEndGameModal={() => setIsOpenEndGameModal(true)}
                                    onClickActionButton={onClickActionButton}
                                    sportType={0}
                                />
                            </div>
                        </div>
                    }
                </div>
                {<div className={styles.dartsBoard}>
                    <PlayBoard
                        mobileView={true}
                        defaultSizeOfDartboard={defaultSizeOfDartboard}
                        dartboardRef={dartboardRef}
                    />
                </div>}
            </div>
        </div>
    );
};

export default TabletsBoard;
