import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DartsPlayerPayload,
    DartsPlayer,
    DartsPlayersTable, IUploadPhotoPayload, DeletePlayerPayload,
} from "./types";
import {toastr} from "react-redux-toastr";
import {API_PLAYERS, API_PLAYERS_PHOTO} from "../../../../constants";
import {errorLogger} from "../../../../services/error-logger";
import {client} from "../../../../services/api.service";
import {upload} from "../../../../services/upload.api";

export interface IGetPlayersResponse {
    totalItems: number,
    totalPages: number,
    pageNum: number,
    pageSize: number,
    items:  DartsPlayer[]
}

const initialState: DartsPlayersTable = {
    dartsPlayers: [] as DartsPlayer[],
    loadingPlayers: false,
    editMode: false,
    editPlayerId: 0,
    isVisibleForm: false,
    noContent: false,
    isEditPhotoMode: false,
    isShowModal: false,
    photoLoader:false,
    totalItems: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
}

export const getDartsPlayers = createAsyncThunk<IGetPlayersResponse,string>(
    'dartsPlayers/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_PLAYERS}?${query}`);
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to fetch players.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Players: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createDartsPlayer = createAsyncThunk<DartsPlayer, DartsPlayerPayload>(
    'dartsPlayers/post',
    async (player, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_PLAYERS, player);
            toastr.success("CMS", "Darts player has been created");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to create a player.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create darts player: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.reponse.data);
        }
    }
)

export const editDartsPlayer = createAsyncThunk<DartsPlayer, DartsPlayerPayload>(
    'dartsPlayers/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_PLAYERS}`, payload);
            toastr.success("CMS", "Player has been edited");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to edit a player.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit darts player: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteDartsPlayer = createAsyncThunk<DartsPlayer, number>(
    'delete-darts-player',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_PLAYERS}/${id}`);
            toastr.success("CMS", "Player has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a player.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete Darts Player: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const uploadPlayersPhoto = createAsyncThunk<string, IUploadPhotoPayload>(
    'dartsPlayersPhoto/upload',
    async (payload, { rejectWithValue}) => {
        try {
            const {data} = await upload.post(`${API_PLAYERS_PHOTO}/${payload.id}`, payload.picture);
            toastr.success("CMS", "Player's photo has been uploaded");
            return data;
        } catch (error) {
            toastr.error('CMS', "Failed to upload a player's photo.")
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Upload Players Photo: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const updatePlayersPhoto = createAsyncThunk<string, IUploadPhotoPayload>(
    'dartsPlayersPhoto/update',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await upload.put(`${API_PLAYERS_PHOTO}/${payload.id}`, payload.picture);
            toastr.success("CMS", "Player's photo has been updated");
            return data;
        } catch (error) {
            toastr.error('CMS', "Failed to update a player's photo.")
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Update Players Photo: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);


        }
    }
)

export const deletePlayersPhoto = createAsyncThunk<DartsPlayer, DeletePlayerPayload>(
    'dartsPlayersPhoto/delete',
    async ({playerId,setIsOpenForm}, {rejectWithValue}) => {
        try {
            const {data} = await upload.delete(`${API_PLAYERS_PHOTO}/${playerId}`);
            toastr.success("CMS", "Player's photo has been deleted");
            setIsOpenForm(false)
            return data;
        } catch (error) {
            toastr.error('CMS', "Failed to delete a player's photo.")
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete Players Photo: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.message);
        }
    }
)


export const cmsPlayersSlice = createSlice({
    name: 'cmsPlayersSlice',
    reducers: {
        setPageNumber: (state, action) => {
            state.pageNum = action.payload
        },
        changePageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setPlayersIsShowModal: (state, action: PayloadAction<boolean>) => {
            state.isShowModal = action.payload
        },
        setPlayersEditPhotoMode: (state, action: PayloadAction<boolean>) => {
            state.isEditPhotoMode = action.payload
        },
        playersShowMode: (state) => {
            state.isVisibleForm = true;
            state.editMode = false;
        },
        playersEditMode: (state, action: PayloadAction<number>) => {
            state.editMode = true;
            state.editPlayerId = action.payload;
            state.isVisibleForm = true;
        },
        playersEditPhotoMode: (state, action: PayloadAction<boolean>) => {
            state.isEditPhotoMode = action.payload;
        },
        playersHideForm: (state) => {
            state.isVisibleForm = false;
        },
        playerSetModal: (state, action: PayloadAction<boolean>) => {
            state.isShowModal = action.payload;
        },
        setPlayerPhotoLoader: (state, action: PayloadAction<boolean>) => {
            state.photoLoader = action.payload;
        },

    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsPlayers.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(getDartsPlayers.fulfilled, (state: DartsPlayersTable, action) => {
                state.dartsPlayers = action.payload.items;
                state.totalPages = action.payload.totalPages;
                state.noContent = !action.payload.items;
                state.loadingPlayers = false;
            })
            .addCase(getDartsPlayers.rejected, (state: DartsPlayersTable) => {
                state.noContent = true;
                state.loadingPlayers = false;
            })
            .addCase(deleteDartsPlayer.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(deleteDartsPlayer.fulfilled, (state: DartsPlayersTable, action) => {
                state.dartsPlayers = state.dartsPlayers.filter(player => player.id !== action.meta.arg);
                state.loadingPlayers = false;
            })
            .addCase(deleteDartsPlayer.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(createDartsPlayer.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(createDartsPlayer.fulfilled, (state: DartsPlayersTable, action) => {
                state.dartsPlayers = state.dartsPlayers.concat(action.payload);
                state.isVisibleForm = false;
                state.loadingPlayers = false;
            })
            .addCase(createDartsPlayer.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(editDartsPlayer.pending, (state: DartsPlayersTable) => {
                state.isVisibleForm = false;
                state.loadingPlayers = true;

            })
            .addCase(editDartsPlayer.fulfilled, (state: DartsPlayersTable, action) => {
                state.editPlayerId = action.meta.arg.id!;
                state.editMode = true;
                state.dartsPlayers = state.dartsPlayers.map(player => player.id === action.payload.id ? action.payload : player)
                state.loadingPlayers = false;
            })
            .addCase(editDartsPlayer.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(updatePlayersPhoto.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(updatePlayersPhoto.fulfilled, (state: DartsPlayersTable,action) => {
                state.loadingPlayers = false;
                state.dartsPlayers.map(player => {
                    if (player.id === action.meta.arg.id){
                        player.avatar = action.payload
                    }
                    return player
                })
            })
            .addCase(updatePlayersPhoto.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(uploadPlayersPhoto.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(uploadPlayersPhoto.fulfilled, (state: DartsPlayersTable,action) => {
                state.loadingPlayers = false;
                state.dartsPlayers.map(player => {
                    if (player.id === action.meta.arg.id){
                        player.avatar = action.payload
                    }
                    return player
                })
            })
            .addCase(uploadPlayersPhoto.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(deletePlayersPhoto.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
                state.isShowModal = false;
                state.photoLoader = true
            })
            .addCase(deletePlayersPhoto.fulfilled, (state: DartsPlayersTable,action) => {
               state.dartsPlayers.map(player =>{
                    if (player.id === action.meta.arg.playerId) {
                        player.avatar = ""
                    }
                    return player
                })
                state.loadingPlayers = false;
                state.photoLoader = false
                state.isShowModal = false
            })
            .addCase(deletePlayersPhoto.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
                state.isShowModal = false
                state.photoLoader = false
            })
    },
});

export const {
    setPageNumber,
    setPlayersIsShowModal,
    setPlayersEditPhotoMode,
    playersShowMode,
    playersEditMode,
    playersEditPhotoMode,
    playersHideForm,
    changePageSize
} = cmsPlayersSlice.actions
export default cmsPlayersSlice.reducer;

