import { useEffect, useState } from "react";

const useIsHidden = (selector: string): boolean => {
    const [isHidden, setIsHidden] = useState<boolean>(true);

    useEffect(() => {
        const checkVisibility = () => {
            const element = document.querySelector(selector);
            if (element) {
                const style = window.getComputedStyle(element);
                setIsHidden(style.display === "none");
            }
        };

        checkVisibility();
        const observer = new MutationObserver(checkVisibility);
        observer.observe(document.body, { attributes: true, subtree: true, childList: true });

        return () => observer.disconnect();
    }, [selector]);

    return isHidden;
};

export default useIsHidden;
