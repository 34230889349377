import {client} from "../../../services/api.service";

export const downloadDailyResult = async (tourId:string) => {
    try {
        const response = await client.get(`api/darts-statistics/daily-results-picture/${tourId}`, {
            responseType: 'blob'
        });

        const contentDisposition = response.headers['content-disposition'];

        let fileName = 'dailyResult.jpg';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+?)$/);
            if (fileNameMatch && fileNameMatch[1]) {
                fileName = decodeURIComponent(fileNameMatch[1]);
            } else {
                const fallbackFileNameMatch = contentDisposition.match(/filename="(.+?)"/);
                if (fallbackFileNameMatch && fallbackFileNameMatch[1]) {
                    fileName = fallbackFileNameMatch[1];
                }
            }
        }

        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};