import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {errorLogger} from "../../../services/error-logger";
import {ModusNewsState, INewsItem, IGetAllNewsResponse} from "./types";
import {
    API_ONDLL_CRUD,
    API_ONDLL_NEWS,
} from "../../../constants";
import {upload} from "../../../services/upload.api";

const initialState: ModusNewsState = {
    news: [] as INewsItem[],
    newsItem: {} as INewsItem,
    isOpen: false,
    isLoading: false,
    editMode: false,
    newsItemId: 0,
    pageNum:1,
    pageSize:10,
    totalItems:0,
    totalPages:0
}

export const getNews = createAsyncThunk<IGetAllNewsResponse,string>(
    'news/get',
    async (queryString, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_ONDLL_NEWS}?${queryString}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch news.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get news: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getSingleNewsItem = createAsyncThunk<{ item:INewsItem }, number>(
    'news/getSingle',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_ONDLL_NEWS}/${id}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch a news item.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get a single news item: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.message);
        }

    }
)

export const createNewsItem = createAsyncThunk<INewsItem, any>(
    'news/post',
    async (user, {rejectWithValue}) => {
        try {
            const {data} = await upload.post(API_ONDLL_CRUD, user);
            toastr.success("CMS", "News item has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a news item.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create news item: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const editNewsItem = createAsyncThunk<INewsItem, any>(
    'news/edit',
    async (newsItem, {rejectWithValue,dispatch}) => {
        try {
            const {data} = await upload.put(`${API_ONDLL_CRUD}`, newsItem.payload);
            toastr.success("CMS", "News item has been edited");
            dispatch(formHandler(false))
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a news item.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit News Iten: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog);
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteNewsItem = createAsyncThunk<INewsItem, number>(
    'news/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_ONDLL_CRUD}/${id}`);
            toastr.success("CMS", "News item has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a news item.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete news item: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            await errorLogger(errorLog)
            return rejectWithValue(error.response.message)
        }

    }
)

export const cmsModusNewsSlice = createSlice({
    name: 'cmsModusNews',
    reducers: {
        formHandler: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setNewsItemId: (state, action: PayloadAction<number>) => {
            state.newsItemId = action.payload;
        },
        editModeHandler: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        setPageNum:(state,action) => {
            state.pageNum = action.payload
        },
        setPageSize:(state,action) => {
            state.pageSize = action.payload
        },
        clearUpdatedNewsInfo: (state)=> {
            state.newsItemId = 0
            state.newsItem = {} as INewsItem
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getNews.pending, (state: ModusNewsState) => {
                state.isLoading = true;
            })
            .addCase(getNews.fulfilled, (state: ModusNewsState, action) => {
                state.isLoading = false;
                state.news = action.payload.items;
                state.totalItems = action.payload.totalItems
                state.totalPages = action.payload.totalPages
            })
            .addCase(getNews.rejected, (state: ModusNewsState) => {
                state.isLoading = false;
            })
            .addCase(getSingleNewsItem.fulfilled, (state: ModusNewsState, action) => {
                state.newsItem = action.payload.item;
                state.editMode = true;
            })
            .addCase(createNewsItem.pending, (state: ModusNewsState) => {
                state.isOpen = false;
                state.isLoading = true;
            })
            .addCase(createNewsItem.fulfilled, (state: ModusNewsState, action) => {
                state.news = state.news.concat(action.payload);
                state.isLoading = false;
            })
            .addCase(createNewsItem.rejected, (state: ModusNewsState) => {
                state.isLoading = false
            })
            .addCase(editNewsItem.pending, (state: ModusNewsState) => {
                state.editMode = false;
                state.isLoading = true;
            })
            .addCase(editNewsItem.fulfilled, (state: ModusNewsState, action) => {
                state.isLoading = false;
                state.news = state.news.map((newsItem) => {
                    if (newsItem.id === action.payload.id) {
                        newsItem = action.payload
                    }
                    return newsItem
                })
                state.newsItemId = 0
            })
            .addCase(editNewsItem.rejected, (state: ModusNewsState) => {
                state.isLoading = false;
            })
            .addCase(deleteNewsItem.pending, (state: ModusNewsState) => {
                state.isLoading = true;
            })
            .addCase(deleteNewsItem.fulfilled, (state: ModusNewsState, action) => {
                state.news = state.news.filter((newsItem) => {
                    return newsItem.id !== action.meta.arg
                })
                state.isLoading = false;
            })
            .addCase(deleteNewsItem.rejected, (state: ModusNewsState) => {
                state.isLoading = false;
            })
    },
});

export const {
    formHandler,
    setNewsItemId,
    editModeHandler,
    setPageNum,
    setPageSize,
    clearUpdatedNewsInfo
} = cmsModusNewsSlice.actions

export default cmsModusNewsSlice.reducer;
