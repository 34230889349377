import React from 'react';
import styles from "./index.module.scss"
import dartsIcon from "../../../../../img/dartsArrows.svg";
import {ILastTurn} from "../../../../../store/dartsGame/currentGame/types";

interface IPlayerBoardProps {
    playerInfo:ILastTurn
}
const PlayerBoard:React.FC<IPlayerBoardProps> = ({playerInfo}) => {
    return (
        <div className={styles.playerBoard}>
            {playerInfo.isIndicated && <div className={styles.activeLine}></div>}
            <div className={styles.playerScoreRow}>
                <div className={styles.player}>
                    <img className={styles.icon} src={dartsIcon} alt="darts"/>
                    <div className={styles.playerName}>{playerInfo.player.name}</div>
                </div>
                <div className={styles.playerScore}>
                    <div className={styles.remainingScore}>{playerInfo.scoreRemaining}</div>
                    <div className={styles.legsWon}>{playerInfo.legsWon}</div>
                </div>
            </div>
            <div className={styles.playerThrowsRow}>
                <div className={styles.score}>{playerInfo.throws[0]}</div>
                <div className={styles.score}>{playerInfo.throws[1]}</div>
                <div className={styles.score}>{playerInfo.throws[2]}</div>
                <div className={styles.turnScore}>{playerInfo.scoreCurrent}</div>
            </div>
        </div>
    );
};

export default PlayerBoard;
