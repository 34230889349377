import React, {useState} from 'react';
import styles from "./index.module.scss"
import {Button, Checkbox, Form, Select} from "semantic-ui-react";
import {formatSelectOptions} from "../../helpers/formatSelectOptions";
import SchemePlayers from "./SchemePlayers/SchemePlayers";
import {useAppSelector} from "../../hooks/hooks";

interface IKnockoutTour {
    totalGames: number
    rounds: ITourRound[]
}

interface IRoundGame {
    gameId: number
    isEditable: boolean
    players: [
        { id: number, name: string },
        { id: number, name: string }
    ]
}

interface ITourRound {
    isCompleted: boolean
    roundName: string
    divider: number
    games: IRoundGame[]
}

const CDCTournament = () => {
    const {editTournament} = useAppSelector(state => state.cmsTournaments)
    const [gameCount, setGameCount] = useState<any>(16);
    const [isIncludePrelim, setIsIncludePrelim] = useState(false);
    const [isOpenPlayerForm, setIsOpenPlayerForm] = useState(false);
    const ELEMENT_HEIGHT = 96
    const ELEMENT_SPACE = 20

    const countMarginTopRound1 = (index: number) => index === 0 ? ELEMENT_HEIGHT / 2 : ELEMENT_HEIGHT + ELEMENT_SPACE
    const countMarginTop = (index: number, divider: number) => index === 0
        ?
        ELEMENT_HEIGHT
        *
        (divider - 1) / 2
        :
        ELEMENT_HEIGHT
        *
        (divider - 1)
        +
        ELEMENT_SPACE

    const countOptions = [
        {key: 8, value: 8, text: 8},
        {key: 16, value: 16, text: 16},
    ]

    const renderRoundHeaderByGamesCount = (gameCount: number, renderElement: (round: string) => JSX.Element) => {
        let count = 0;
        const elements: JSX.Element[] = [];

        while (gameCount > 1 && gameCount % 2 === 0) {
            gameCount /= 2;
            count++;
            elements.push(renderElement(`Round ${count}`));
        }
        elements.push(renderElement("Final"));
        return elements;
    };

    const renderRoundHeader = (round: string) => {
        return (
            <div className={styles.headerRound}>
                <div className={styles.title}>{round}</div>
            </div>
        )
    }

    const mockKnockoutTour: IKnockoutTour = {
        totalGames: 16,
        rounds: [
            {
                isCompleted: false,
                roundName: "Round 1",
                divider: 0,
                games: Array.from({length: 16}, (_, index) => ({
                    gameId: index + 1,
                    isEditable: false,
                    players: [
                        {id: index * 2 + 1, name: `John Doe ${index * 2 + 1}`},
                        {id: index * 2 + 2, name: `Jane Smith ${index * 2 + 2}`}
                    ]
                }))
            },
            {
                isCompleted: false,
                roundName: "Round 2",
                divider: 2,
                games: Array.from({length: 8}, (_, index) => ({
                    gameId: 17 + index,
                    isEditable: false,
                    players: [
                        {id: 33 + index * 2, name: `Michael Brown ${33 + index * 2}`},
                        {id: 34 + index * 2, name: `Emily Davis ${34 + index * 2}`}
                    ]
                }))
            },
            {
                isCompleted: false,
                roundName: "Round 3",
                divider: 4,
                games: Array.from({length: 4}, (_, index) => ({
                    gameId: 25 + index,
                    isEditable: false,
                    players: [
                        {id: 49 + index * 2, name: `William Johnson ${49 + index * 2}`},
                        {id: 50 + index * 2, name: `Olivia Wilson ${50 + index * 2}`}
                    ]
                }))
            },
            {
                isCompleted: false,
                roundName: "Round 4",
                divider: 8,
                games: Array.from({length: 2}, (_, index) => ({
                    gameId: 25 + index,
                    isEditable: false,
                    players: [
                        {id: 49 + index * 2, name: `William Johnson ${49 + index * 2}`},
                        {id: 50 + index * 2, name: `Olivia Wilson ${50 + index * 2}`}
                    ]
                }))
            },
            {
                isCompleted: false,
                roundName: "Final",
                divider: 16,
                games: Array.from({length: 1}, (_, index) => ({
                    gameId: 25 + index,
                    isEditable: false,
                    players: [
                        {id: 49 + index * 2, name: `William Johnson ${49 + index * 2}`},
                        {id: 50 + index * 2, name: `Olivia Wilson ${50 + index * 2}`}
                    ]
                }))
            }
        ]
    };

    const pickupMarginTop = (roundIndex: number, index: number, divider: number) => {
        if (!roundIndex) {
            return
        }
        if (roundIndex === 1) {
            return {marginTop: countMarginTopRound1(index) + "px"}
        } else {
            return {marginTop: countMarginTop(index, divider) + "px"}
        }
    }

    const renderRoundsWhenTourStarted = (tour: IKnockoutTour) => {
        return tour.rounds.map((round, roundIndex) => {
            return (
                <div className={roundIndex === 0 ? styles.round1 : styles.round}>
                    {round.games.map((game, index) => (
                        <div
                            key={`${roundIndex}-${index}`}
                            className={styles.gameModule}
                            style={pickupMarginTop(roundIndex, index, round.divider)}
                        >
                            <div className={styles.decor}>{index + 1}</div>
                            <div className={styles.playersInputs}>
                                <div className={styles.inputWrapper}>
                                    <Select
                                        defaultValue={game.players[0].id}
                                        value={game.players[0].id}
                                        options={formatSelectOptions(game.players)}
                                        placeholder="Select player"
                                    />
                                </div>
                                <div className={styles.inputWrapper}>
                                    <Select
                                        defaultValue={game.players[1].id}
                                        value={game.players[1].id}
                                        options={formatSelectOptions(game.players)}
                                        placeholder="Select player"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        })
    }


    return (
        <div className={styles.overlay}>
            {isOpenPlayerForm &&
                <SchemePlayers onClose={() => setIsOpenPlayerForm(false)}/>
            }
            <Form className={styles.form}>
                <div className={styles.header}>
                    <div>
                        <div className={styles.tourName}>
                            <p>Name:</p>
                            <p>{editTournament?.competitionName}</p>
                        </div>
                        <div className={styles.gameCounter}>
                            <p className={styles.inputLabel}>Game count:</p>
                            <div className={styles.gameCountWrapper}>
                                <Select
                                    options={countOptions}
                                    value={gameCount}
                                    onChange={(e, {value}) => setGameCount(value)}
                                    placeholder={"Select count"}
                                />
                            </div>
                        </div>
                        <div>
                            <Checkbox
                                className={styles.prelimCheck}
                                checked={isIncludePrelim}
                                onClick={() => setIsIncludePrelim(!isIncludePrelim)}
                                label={"include prelim"}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            onClick={() => setIsOpenPlayerForm(true)}
                            color={"green"}>
                            Player's list
                        </Button>
                    </div>
                </div>
                <div className={styles.tourHeader}>
                    <div className={styles.headerRound}>
                        <div className={styles.title}>Prelim</div>
                    </div>
                    {renderRoundHeaderByGamesCount(gameCount, renderRoundHeader)}
                </div>
                <div className={styles.tourColumn}>
                    <div className={styles.round1}>
                        {new Array(gameCount).fill(null)?.map((_, index) => (
                            <div className={styles.gameModule}>
                                <div className={styles.decor}>{index + 1}</div>
                                <div className={styles.playersInputs}>
                                    <div className={styles.inputWrapper}>
                                        <Select
                                            options={[]}
                                            placeholder={"Select player"}
                                        />
                                    </div>
                                    <div className={styles.inputWrapper}>
                                        <Select
                                            options={[]}
                                            placeholder={"Select player"}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {renderRoundsWhenTourStarted(mockKnockoutTour)}
                    {/*{renderRoundGamesByGamesCount(gameCount)}*/}
                </div>
            </Form>
        </div>
    );
};

export default CDCTournament;
