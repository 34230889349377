import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss"
import {Button, Dropdown, Form, Icon, Label} from "semantic-ui-react";
import {formatSelectOptions} from "../../../helpers/formatSelectOptions";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {editDartsTournament} from "../../../store/cms/darts/tournaments/cmsTournamentsSlice";
import {IEditTournamentPayload} from "../../../store/cms/darts/tournaments/types";

interface SchemePlayersProps {
    onClose: () => void
}

const SchemePlayers:React.FC<SchemePlayersProps> = ({onClose}) => {
    const dispatch = useAppDispatch()
    const {editTournament, players} = useAppSelector(state => state.cmsTournaments)
    const [selectedPlayers, setSelectedPlayers] = useState<any>([]);

    useEffect(() => {
        if (editTournament) {
            setSelectedPlayers(editTournament.playerIDs)
        }
    }, []);

    const approvePlayers = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (editTournament) {
            const updatedTournament: IEditTournamentPayload = {
                id: editTournament ? editTournament.id : null,
                competitionId: editTournament.competitionId,
                weekId: editTournament.weekId,
                groupId: editTournament.groupId,
                phaseId: editTournament.phaseId,
                type: editTournament.type,
                playerIDs: selectedPlayers
            };
            dispatch(editDartsTournament(updatedTournament));
        }
    }
    return (
        <div className={styles.overlay}>
            <form className={styles.form}>
                <button
                    onClick={(e)=>{
                        e.preventDefault();
                        onClose()
                    }}
                    className={styles.closeBtn}>
                    <Icon name={"close"}/>
                </button>
                <h1 className={styles.title}>Tournament's players</h1>
                <Form.Field>
                    <Label>Selected players:</Label>
                    <div className={styles.inputInner}>
                        <Dropdown
                            onChange={(e, {value}) => setSelectedPlayers(value)}
                            value={selectedPlayers}
                            options={formatSelectOptions(players)}
                            placeholder='Select players for tournament'
                            fluid
                            multiple
                            selection
                            search
                        />
                    </div>
                </Form.Field>
                <div className={styles.submitBtn}>
                    <Button
                        onClick={(e) => approvePlayers(e)}
                        color={"green"}>OK
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default SchemePlayers;
