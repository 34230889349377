import React, {useEffect, useState} from "react";
import {Button, Form, Icon, Input, Label, Message, Select,} from "semantic-ui-react";
import {Controller, useForm} from "react-hook-form";
import Flatpickr from "react-flatpickr";
import TimePicker from "rc-time-picker";
import {yupResolver} from "@hookform/resolvers/yup";
import moment, {Moment} from "moment";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {createDartsGame, editDartsGame, hideGamesForm} from "../../../../store/cms/darts/games/cmsGamesSlice";
import {ICreateDartsGamePayload, IEditDartsGamePayload} from "../../../../store/cms/darts/games/types";
import {IDartsTournament} from "../../../../store/cms/darts/tournaments/types";
import useGetOptions from "./hooks/useGetOptions";
import {createValidationSchema} from "./helpers/getValidationSchema";
import {createTitleForDatePicker} from "./helpers/getTitleForDatePicker";
import CheckboxCms from "../../../../components/CheckboxCms/CheckboxCms";

const DartsGamesForm = () => {
    const [dateStart, setDateStart] = useState<Date | string>(new Date());
    const [timeValue, setTimeValue] = useState<Moment>(moment());
    const [doubleStart, setDoubleStart] = useState(false);
    const [startScore, setStartScore] = useState<any>(501);
    const [selectedTour, setSelectedTour] = useState<IDartsTournament | null>(null)
    const dispatch = useAppDispatch();
    const {editMode, dartsGames, editGameId} = useAppSelector((state) => state.cmsGames);
    const {countries, tournaments, players} = useAppSelector((state) => state.cmsGames.initGamesData);
    const {countriesOptions, playersOptions, tournamentsOptions} = useGetOptions({countries, tournaments, players})

    const startScoreOptions = [
        {key: 301, value: 301, text: "301"},
        {key: 501, value: 501, text: "501"},
        {key: 701, value: 701, text: "701"}
    ]

    useEffect(() => {
        setValue("location", "GB");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDefaultFormValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editGameId, editGameId]);

    const setDefaultFormValues = () => {
        if (editMode && editGameId) {
            const editGame = dartsGames?.find((game) => game.id === editGameId);
            const defaultTournamentsOptions = tournamentsOptions
                .find((tour) => tour.text === `${editGame!.phaseName}, ${editGame!.weekName}, ${editGame!.groupName}`);
            const defaultCountryOptions = countriesOptions?.find((country) => country.value === editGame!.location);
            const defaultHomePlayerOptions = playersOptions?.find((player) => player.text === editGame!.playerHomeName);
            const defaultAwayPlayerOptions = playersOptions?.find((player) => player.text === editGame!.playerAwayName);
            if (editGame) {
                setValue("legsToWin", editGame.legsToWin)
                setDoubleStart(editGame.doubleStart)
                setStartScore(editGame.startScore)
            }
            setValue("tournamentName", defaultTournamentsOptions?.value);
            setValue("location", defaultCountryOptions?.key);
            setValue("playerHomeName", defaultHomePlayerOptions?.value);
            setValue("playerAwayName", defaultAwayPlayerOptions?.value);

            setDateStart(editGame!.timeStart);
            setTimeValue(moment(editGame!.timeStart));
            if (defaultTournamentsOptions) {
                const currentTour = tournaments?.find(tour => tour.id === defaultTournamentsOptions.value)
                if (currentTour) {
                    setSelectedTour(currentTour)
                }
            }


        }
    }

    const gamesValidationSchema = createValidationSchema({
        dateStart,
        setSelectedTour,
        tournaments
    });

    const {control, errors, handleSubmit, setValue, getValues} = useForm({
        mode: "onChange",
        resolver: yupResolver(gamesValidationSchema),
    });
    const formatTimeStart = () => {
        const localDateTime = moment(`${moment(dateStart).format("YYYY-MM-DD")}T${timeValue.format("HH:mm:ss")}`);
        return localDateTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    }
    const handleSubmitGame = () => {
        const game: ICreateDartsGamePayload = {
            tournamentId: getValues("tournamentName"),
            playerHomeId: getValues("playerHomeName"),
            playerAwayId: getValues("playerAwayName"),
            status: 1,
            isActive: true,
            location: getValues("location"),
            timeStart: formatTimeStart(),
            legsToWin: getValues("legsToWin") ? Number(getValues("legsToWin")) : 4,
            doubleStart,
            startScore
        };
        dispatch(createDartsGame(game));
    };

    const submitEditGame = () => {
        const editGame: IEditDartsGamePayload = {
            id: editGameId!,
            tournamentId: getValues("tournamentName"),
            playerHomeId: getValues("playerHomeName"),
            playerAwayId: getValues("playerAwayName"),
            status: 1,
            location: getValues("location"),
            timeStart: formatTimeStart(),
            legsToWin: getValues("legsToWin"),
            doubleStart,
            startScore
        };
        dispatch(editDartsGame(editGame));
    };

    const changeTimeStart = (value: Moment) => {
        setTimeValue(value);
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    const onCloseForm = () => {
        dispatch(hideGamesForm())
    }

    return (
        <div className="form-overlay">
            <Form
                onSubmit={
                    editMode
                        ? handleSubmit(submitEditGame)
                        : handleSubmit(handleSubmitGame)
                }
                className={"darts-game-form"}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={onCloseForm}
                    style={{float: "right", cursor: "pointer"}}
                />
                <div className={"dartsGame-form-row"}>
                    <Form.Field className={"half-width-field"}>
                        <Label>Tournament:</Label>
                        <Controller
                            name="tournamentName"
                            control={control}
                            render={(props) => (
                                <Select
                                    value={getValues("tournamentName")}
                                    id="tournamentName"
                                    options={tournamentsOptions}
                                    placeholder="Select tournament"
                                    onChange={(e, {value}) => props.onChange(value)}
                                />
                            )}
                        />
                        {errors.tournamentName && (
                            <Message negative>{"Tournament is required field"}</Message>
                        )}
                    </Form.Field>
                    <Form.Field className={"half-width-field"}>
                        <div className={"temporary-message"}>will be soon</div>
                        <Label>Group</Label>
                        <Select
                            id="GroupName"
                            options={[]}
                            placeholder="Select group"
                        />
                    </Form.Field>
                </div>
                <div className={"dartsGame-form-row"}>
                    <Form.Field className={"half-width-field"}>
                        <Label>Player 1:</Label>
                        <Controller
                            name="playerHomeName"
                            control={control}
                            render={(props) => (
                                <Select
                                    value={getValues("playerHomeName")}
                                    id="playerHomeName"
                                    options={playersOptions}
                                    placeholder="Select home player name"
                                    onChange={(e, {value}) => props.onChange(value)}
                                />
                            )}
                        />
                        {errors.playerHomeName && (
                            <Message negative>{"Player1 is required field"}</Message>
                        )}
                    </Form.Field>
                    <Form.Field className={"half-width-field"}>
                        <Label>Player 2:</Label>
                        <Controller
                            name="playerAwayName"
                            control={control}
                            render={(props) => (
                                <Select
                                    value={getValues("playerAwayName")}
                                    id="playerAwayName"
                                    options={playersOptions}
                                    placeholder="Select away player name"
                                    onChange={(e, {value}) => props.onChange(value)}
                                />
                            )}
                        />
                        {errors.playerAwayName && (
                            <Message negative>{"Player2 is required field"}</Message>
                        )}
                        {errors.isValid && (
                            <Message negative>{errors.isValid.message}</Message>
                        )}
                    </Form.Field>
                </div>
                <Form.Field className={"half-width-field"}>
                    <Label>Location:</Label>
                    <Controller
                        name="location"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("location")}
                                options={countriesOptions}
                                placeholder="Select country"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.location && (
                        <Message negative>{"Location is required field"}</Message>
                    )}
                </Form.Field>
                <div className={"dartsGame-form-row"}>
                    <Form.Field className={"half-width-field"}>
                        <Label>{createTitleForDatePicker(selectedTour)}</Label>
                        <Flatpickr
                            name="date"
                            value={dateStart}
                            options={dayPickerOptions}
                            onChange={(date: Date, dayStr: string) => setDateStart(dayStr)}
                        />
                        {errors.isValidTournamentDate && (
                            <Message negative>{errors.isValidTournamentDate.message}</Message>
                        )}
                    </Form.Field>
                    <Form.Field className={"half-width-field"}>
                        <Label>Fixture Time:</Label>
                        <div>
                            <TimePicker
                                showSecond={false}
                                value={timeValue}
                                onChange={changeTimeStart}
                            />
                        </div>
                    </Form.Field>
                </div>
                <Form.Field className={"half-width-field"}>
                    <Label>Start score</Label>
                    <Controller
                        name="startScore"
                        control={control}
                        render={(props) => (
                            <Select
                                value={startScore}
                                id="playerAwayName"
                                options={startScoreOptions}
                                placeholder="Select start score"
                                onChange={(e, {value}) => {
                                    props.onChange(value)
                                    setStartScore(value)
                                }}
                            />
                        )}
                    />
                </Form.Field>
                <div className={"dartsGame-form-legs"}>
                    <Form.Field className={"leg-to-win-field"}>
                        <Label className={'darts-game-adc-label'}>Legs to win (min: 2 max: 9)</Label>
                        <Controller
                            name="legsToWin"
                            control={control}
                            render={(props) => (
                                <Input
                                    defaultValue={4}
                                    min={2}
                                    max={9}
                                    name='legsToWin'
                                    placeholder="Select legs to win (number)"
                                    value={getValues("legsToWin")}
                                    type='number'
                                    id="legsToWin"
                                    onChange={(e, {value}) => props.onChange(value)}/>
                            )}
                        />
                        {errors.legsToWin && (
                            <Message negative>{errors.legsToWin.message}</Message>
                        )}
                    </Form.Field>
                    <div className={"double-start"}>Double Start
                        <CheckboxCms value={doubleStart} handler={() => setDoubleStart(!doubleStart)}/>
                    </div>
                </div>
                <div className={"create-game-submit"}>
                    <Button id="save_game" color="green" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default DartsGamesForm;
