import {IDartsTour} from "../../../../../../store/cms/darts/tournaments/types";

interface IProps {
    tournamentsIds: IDartsTour[];
    editMode: boolean;
    editTournament: IDartsTour | null;
    selectedWeek: number;
    selectedGroup: number;
    selectedCompetition: number;
}

export const checkIsExistTournament = (props: IProps) => {
    const {
        tournamentsIds,
        editMode,
        editTournament,
        selectedWeek,
        selectedGroup,
        selectedCompetition
    } = props;

    if (editMode && editTournament) {
        const filteredTournamentsIds = tournamentsIds.filter(
            (tour: { id: number; }) => tour.id !== editTournament.id
        );

        return filteredTournamentsIds.find(
            (tour) =>
                tour.weekId === selectedWeek &&
                tour.groupId === selectedGroup &&
                tour.competitionId === selectedCompetition
        );
    } else {
        return Boolean(tournamentsIds.find(
            (tour) =>
                tour.weekId === selectedWeek &&
                tour.groupId === selectedGroup &&
                tour.competitionId === selectedCompetition
        ));
    }
};
