import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ILiveScoreInitPayload,
    ILiveScoreInitPayloadByTour,
    ILiveScoreLocations,
    ILiveScoreReducer,
    ILiveScoreSidebar,
    LiveScoreHeader,
    LiveScoreRootObject,
    LiveScoreSidebar,
    LiveScoreStatistics,
    LiveScoreTimelines,
    LiveScoreTurnShots,
    OnShotForLiveScore,
    ShotUpdateSocketData
} from "./types";
import {API_LIVE_SCORE_INIT, API_LIVE_SCORE_INIT_BY_TOUR, API_LIVE_SCORE_SIDEBAR} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {formatSideBar} from "./helpers/formatSideBar";
import moment from "moment/moment";

const initialState: ILiveScoreReducer = {
    allInformation: {
        liveScore: {
            header: {
                gameId: 0,
                legNum: 0,
                legs: [],
                scores: [],
                players: [
                    {
                        id: 0,
                        name: ""
                    },
                    {
                        id: 0,
                        name: ""
                    }
                ],
                nextPlayerId: 0,
                status: 2,
                turnShots: {} as LiveScoreTurnShots
            },
            timelines: {} as LiveScoreTimelines,
            statistics: {
                gameId: 0,
                statistics: [],
                players: [],
                groups: []
            }
        },
        sidebar: {} as ILiveScoreSidebar,
        sidebarEvents: {},
        gameStartDate: ''
    },
    lastShot: {} as OnShotForLiveScore,
    liveScoreLoader: false,
    activeSideBarEvent: 0,
    isAdc: false
}

export const getLiveScoreInfo = createAsyncThunk<LiveScoreRootObject, ILiveScoreInitPayload, { rejectValue: string }>(
    'liveScoreInfo/schedule',
    async (payload, {rejectWithValue}) => {
        const {gameId, gameType} = payload;
        try {
            const {data} = await client.get(`${API_LIVE_SCORE_INIT}/${gameType}/${gameId}`);
            return data;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }

    }
)

export const getLiveScoreInfoByTour = createAsyncThunk<LiveScoreRootObject, ILiveScoreInitPayloadByTour, { rejectValue: string }>(
    'liveScoreInfoByTour/schedule',
    async (payload, {rejectWithValue}) => {
        const {tourId} = payload;
        try {
            const {data} = await client.get(`${API_LIVE_SCORE_INIT_BY_TOUR}/${tourId}`);
            return data;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }

    }
)

export const changeGameLiveScore = createAsyncThunk<LiveScoreRootObject, ILiveScoreInitPayload, { rejectValue: string }>(
    'liveScoreInfo/changeGame',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_LIVE_SCORE_INIT}/${payload.gameType?.toLocaleLowerCase()}/${payload.gameId}`);
            return data;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message);
            return rejectWithValue(e.response.data);
        }

    }
)

export const updateSidebar = createAsyncThunk<ILiveScoreLocations[], string, { rejectValue: string }>(
    'liveScoreInfo/updateSideBar',
    async (date, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_LIVE_SCORE_SIDEBAR}/${moment(date).format("YYYY-MM-DD")}`);
            return data.locations;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message);
            return rejectWithValue(e.response.data);
        }
    }
)
export const liveScoreInfoSlice = createSlice({
    name: 'liveScoreInfo',
    reducers: {
        setIsADC: (state, action) => {
            state.isAdc = action.payload
        },
        setLastShot: (state, action: PayloadAction<ShotUpdateSocketData>) => {
            const {bed, ring, message} = action.payload;
            if (state.allInformation.liveScore.header.gameId === action.payload.gameId) {
                state.lastShot = {bed, ring, message}
            } else {
                return state
            }
        },
        setLiveScoreHeader: (state, action: PayloadAction<LiveScoreHeader>) => {
            if (state.allInformation.liveScore.header.gameId === action.payload.gameId) {
                state.allInformation.liveScore.header = action.payload
            } else {
                return state
            }

        },

        setLiveScoreTimelines: (state, action: PayloadAction<LiveScoreTimelines>) => {
            if (state.allInformation.liveScore.header.gameId === action.payload.gameId) {
                state.allInformation.liveScore.timelines = action.payload
            } else {
                return state
            }

        },

        setLiveScoreStatistic: (state, action: PayloadAction<LiveScoreStatistics>) => {
            if (state.allInformation.liveScore.header.gameId === action.payload.gameId) {
                state.allInformation.liveScore.statistics = action.payload
            } else {
                return state
            }

        },

        setSidebar: (state, action: PayloadAction<LiveScoreSidebar>) => {
            state.allInformation.sidebarEvents = formatSideBar(action.payload.locations);
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLiveScoreInfo.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(getLiveScoreInfo.fulfilled, (state: ILiveScoreReducer, action) => {
                state.allInformation.liveScore = {
                    header: action.payload.header,
                    statistics: action.payload.statistics,
                    timelines: action.payload.timelines
                };
                state.allInformation.sidebar = action.payload.sidebar;
                state.activeSideBarEvent = action.meta.arg.gameId;
                state.liveScoreLoader = false;
                if (action.payload.sidebar) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload.sidebar.locations);
                }

            })
            .addCase(getLiveScoreInfo.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(getLiveScoreInfoByTour.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(getLiveScoreInfoByTour.fulfilled, (state: ILiveScoreReducer, action) => {
                state.allInformation.liveScore = {
                    header: action.payload.header,
                    statistics: action.payload.statistics,
                    timelines: action.payload.timelines
                };
                state.allInformation.sidebar = action.payload.sidebar;
                state.liveScoreLoader = false;
                if (action.payload.sidebar) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload.sidebar.locations);
                }
            })
            .addCase(getLiveScoreInfoByTour.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(changeGameLiveScore.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(changeGameLiveScore.fulfilled, (state: ILiveScoreReducer, action) => {
                state.liveScoreLoader = false;
                state.allInformation.liveScore = {
                    header: action.payload.header,
                    statistics: action.payload.statistics,
                    timelines: action.payload.timelines
                };
                state.activeSideBarEvent = action.meta.arg.gameId;
                state.liveScoreLoader = false;
            })
            .addCase(changeGameLiveScore.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(updateSidebar.fulfilled, (state: ILiveScoreReducer, action) => {
                if (action.payload) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload);
                }
            })
    }
})

export const {
    setLastShot,
    setSidebar,
    setLiveScoreHeader,
    setLiveScoreTimelines,
    setLiveScoreStatistic,
    setIsADC
} = liveScoreInfoSlice.actions;
export default liveScoreInfoSlice.reducer;
