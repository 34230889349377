import React from 'react';
import {Button} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {setIsFinalDailyResult} from "../../../../store/dailyResults/dailyResultSlice";
import {DartsADCGame, DartsGame} from "../../../../store/pages/schedule/types";
import {GameTypes} from "../../../../enums/gameEvents";
import {DartsGameItem} from "../../../../store/dartsGame/handler/types";
import {checkUserAccessByPermission, PagePermissionsDictionary} from "../../../../helpers/checkUserAccessByPermission";

interface IScheduleInformButtonsProps {
    selectedTournament: number
    selectedGame: number
    games: DartsGame[]
    isAdcGameSelected: boolean
    activeAdcGame: DartsADCGame | null | undefined
    selectedAdcGame: number
    selectedGameType: GameTypes | null

}

const ScheduleInformButtons: React.FC<IScheduleInformButtonsProps> = (
    {
        selectedTournament,
        selectedGame,
        games,
        isAdcGameSelected,
        activeAdcGame,
        selectedAdcGame,
        selectedGameType

    }) => {
    const dispatch = useAppDispatch()
    const history = useHistory();
    const {userPermissions} = useAppSelector((state) => state.auth);

    const goToLeagueList = () => {
        history.push(`/darts/leagues/${selectedTournament}`);
    };

    const goToDailyResult = () => {
        history.push(`daily-results/${selectedTournament}`);
        dispatch(setIsFinalDailyResult(null))
    };

    const gotToLiveScoreDarts = () => {
        if (!selectedGame && selectedTournament) {
            return history.push(`darts/livescore/${selectedTournament}`);
        }
        if (activeAdcGame || isAdcGameSelected || selectedAdcGame) {
            return history.push(`darts/livescore/${GameTypes.ADC.toLowerCase()}/${selectedAdcGame}`);
        }
        if (selectedGame && !isAdcGameSelected) {
            return history.push(`darts/livescore/${GameTypes.CLASSIC.toLowerCase()}/${selectedGame}`);
        }
        const chosenGame = games.find((game: DartsGameItem) => game.id === selectedGame)
        if (chosenGame!.is9shots) {
            return alert("This widget for regular games only ")
        }

        history.push(`darts/livescore/${selectedGame}`);
    };

    return (
        <div className="schedule__login">
            {!!(selectedTournament) &&
                <>
                    {checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.LeagueTable) &&
                        <Button
                            id="league_table"
                            color="brown"
                            onClick={goToLeagueList}
                        >
                            League table
                        </Button>
                    }
                    <Button
                        id="league_table"
                        color="olive"
                        onClick={() => goToDailyResult()}
                    >
                        Daily results
                    </Button>
                    {selectedTournament
                        &&
                        checkUserAccessByPermission(userPermissions, PagePermissionsDictionary.LiveScore)
                        &&
                        selectedGameType !== GameTypes.SHOOTOUT
                        &&
                        <Button
                            id="live_score"
                            color="blue"
                            onClick={gotToLiveScoreDarts}
                        >
                            Live score
                        </Button>}
                </>
            }
        </div>
    );
};

export default ScheduleInformButtons;
