import * as yup from "yup";
import moment from "moment";
import {IDartsTournament} from "../../../../../store/cms/darts/tournaments/types";
import React from "react";

interface ICreateValidationSchema {
    dateStart: string | Date;
    setSelectedTour: React.Dispatch<React.SetStateAction<IDartsTournament | null>>;
    tournaments: IDartsTournament[]
}

export const createValidationSchema = (
    {
        dateStart,
        tournaments,
        setSelectedTour
    }: ICreateValidationSchema
) => {
    return yup.object().shape({
        tournamentName: yup.string().required(),
        playerHomeName: yup.string().required(),
        playerAwayName: yup.string().required(),
        location: yup.string().required(),
        isValid: yup
            .string()
            .test("match", "You cannot select the same players", function () {
                return this.parent.playerHomeName !== this.parent.playerAwayName || !this.parent.playerHomeName?.trim() || !this.parent.playerAwayName?.trim()
            }),
        isValidTournamentDate: yup
            .string()
            .test("match", "Selected date is out of range", function (): boolean {
                const currentTour = tournaments.find(tour => tour.id === +this.parent.tournamentName)

                if (currentTour) {
                    setSelectedTour(currentTour)
                    return moment(dateStart).toDate() >= moment(currentTour!.dateStart).toDate()
                        &&
                        moment(dateStart).toDate() <= moment(currentTour!.dateEnd).toDate()
                }
                return false
            }),
    })
}
